import { FC } from 'react'

import { ControlT } from 'types/internal'
import { ReactComponent as IconCameraSlash } from 'shared/assets/faCameraSlash.svg'
import { ReactComponent as IconMicrophoneSlash } from 'shared/assets/faMicrophoneSlash.svg'

type Props = {
  controls: ControlT
  handleMicOrCameraClick: (v: keyof ControlT) => void
}

const RecorderControls: FC<Props> = ({ controls, handleMicOrCameraClick }) => {
  const renderContent = () => {
    if (!controls.camera && !controls.mic) {
      return (
        <div className='flex flex-col portrait:gap-8 landscape:gap-12'>
          <div onClick={() => handleMicOrCameraClick('camera')}>
            <IconCameraSlash />
          </div>
          <div onClick={() => handleMicOrCameraClick('mic')}>
            <IconMicrophoneSlash />
          </div>
        </div>
      )
    } else if (!controls.mic) {
      return (
        <div onClick={() => handleMicOrCameraClick('mic')}>
          <IconMicrophoneSlash />
        </div>
      )
    } else if (!controls.camera) {
      return (
        <div onClick={() => handleMicOrCameraClick('camera')}>
          <IconCameraSlash />
        </div>
      )
    }
  }

  if (controls.camera && controls.mic) {
    return null
  } else {
    return (
      <div className='w-full h-full absolute top-0 left-0 text-white z-10 flex items-center justify-center landscape:text-5xl portrait:text-4xl'>
        {renderContent()}
      </div>
    )
  }
}

export default RecorderControls
