const conf = {
  backendUrl: 'https://facesign-dev-api.web.app',
  isDev: true,
  apiKey: 'AIzaSyC9Yrb5KsOSLelGbl9ymc-90SsNZCPTkDI',
  authDomain: 'facesignai-dev.firebaseapp.com',
  projectId: 'facesignai-dev',
  storageBucket: 'facesignai-dev.appspot.com',
  messagingSenderId: '511529342332',
  appId: '1:511529342332:web:a48b075c56218f48a240f9',
  apiUrl: 'https://api.dev.facesign.ai',
  firebaseStorageId: 'facesignai-dev.appspot.com',
  socketServerUrl: 'sd.facesign.ai'
}

module.exports = conf
