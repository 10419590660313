import {
  ForwardRefRenderFunction,
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import { ReactComponent as IconXMark } from 'shared/assets/faXMark.svg'

type Props = {
  overlay?: boolean
  children: ReactNode
  showFooter?: boolean
  customFooter?: ReactNode
}

export interface IModal {
  open: () => void
  close: () => void
}

const Modal: ForwardRefRenderFunction<IModal, Props> = (
  { overlay = false, children, showFooter = true, customFooter },
  ref
) => {
  const [show, setShow] = useState<boolean>(false)

  useImperativeHandle(ref, () => ({
    open: () => {
      onOpen()
    },
    close: () => {
      onClose()
    }
  }))

  const escFunction = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape' && show) {
        setShow(false)
      }
    },
    [show]
  )

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [escFunction])

  const onOpen = () => {
    setShow(true)
  }

  const onClose = () => {
    setShow(false)
  }

  const variants = {
    default: `${
      show ? 'flex' : 'hidden'
    } opacity-50 fixed inset-0 z-40 bg-black`,
    overlay: `${show ? 'flex' : 'hidden'} opacity-0 fixed inset-0 z-40 bg-black`
  }

  return (
    <>
      <div
        className={`${
          show ? 'flex' : 'hidden'
        } landscape:absolute portrait:fixed landscape:top-[50%] landscape:left-[50%] portrait:top-0 portrait:left-0 portrait:right-0 landscape:w-auto portrait:w-[100vw] portrait:h-[100vh] landscape:my-6 landscape:mx-auto landscape:max-w-3xl landscape:transform landscape:translate-x-[-50%] landscape:translate-y-[-50%] z-50`}
      >
        <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
          <div className='relative p-6 flex-auto overflow-y-auto h-fit-content'>
            <div>
              <button
                className='absolute top-5 right-10 z-50'
                onClick={() => onClose()}
              >
                <IconXMark />
              </button>
            </div>
            {children}
          </div>
          {showFooter && !customFooter && (
            <div className='flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b'>
              <button
                className='bg-emerald-500 text-gray-600 active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                type='button'
                onClick={onClose}
              >
                Okay
              </button>
            </div>
          )}
          {customFooter}
        </div>
      </div>
      <div
        className={overlay ? variants.default : variants.overlay}
        onClick={onClose}
      />
    </>
  )
}

export default forwardRef(Modal)
