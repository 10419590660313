import * as API from '@facesignai/api'

type Phrase = API.Phrase

export type Error = {
  error: string
}

export type SessionStartRequest = {
  version: string
}

export type SessionStartResponse = {
  steps: Phrase[]
  avatar: API.AvatarType
  dgKey: string
  azureKey?: { token: string; region: string }
  heygenKey?: string
  avatarId?: string
  voiceId?: string
}

export type SubmitReplyRequest = {
  reply: string
}

export type SubmitReplyResponse = {
  phrase: Phrase
  isOver: boolean
  steps: Phrase[]
}

export type ApplyResponseRequest = {
  q: Phrase
}

export type AvatarLatencyRequest = {
  phraseId: string
  latency: number
}

export type ApplyResponseResponse = {
  steps: Phrase[]
}

export type ControlT = {
  camera: boolean
  mic: boolean
}

export enum PermissionErrorType {
  explanation = 'explanation',
  systemDenied = 'systemDenied',
  userDenied = 'userDenied',
  trackError = 'trackError'
}
