import { FC, useState } from 'react'

import ConfirmationCheckbox from 'components/ConfirmationCheckbox'
import { sendCode } from 'controllers/main'
import { ReactComponent as IconLogo } from 'assets/FaceSignAILogo.svg'
import { TwoFactorT } from 'types/twoFactor'


type Props = {
  onComplete: (email: string) => void
  variant: TwoFactorT.VariantT
  isTest: boolean
  model: string | null
  avatar: 'heygen' | 'azure'
}

const EnterEmail: FC<Props> = ({
  onComplete,
  variant,
  isTest,
  model,
  avatar
}) => {
  const [value, setValue] = useState('')
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [agreed, setAgreed] = useState(false)

  const validateEmailRegEx = (email: string) => {
    return email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }

  const submit = async () => {
    if (value === '') {
      setError('Enter your email please')
    } else {
      const localCheck = validateEmailRegEx(value)
      if (!localCheck) {
        setError('Enter valid email')
      } else {
        onComplete(value)
        const res = await sendCode(
          value,
          agreed,
          variant,
          isTest,
          model,
          avatar
        )
        console.log('success', JSON.stringify(res))
        if (res?.success && res.verificationId && res.attemptId) {
          // @ts-ignore
          window.fireTestEvent && window.fireTestEvent('ev_email_sent', res)
        }
      }
    }
  }

  const heading = (
    <div className='flex flex-row w-full landscape:px-24 portrait:pl-2 portrait:pr-4 items-center justify-between py-6 w-full'>
        <a href='https://www.facesign.ai/'>
          <IconLogo width='138' height='32' />
        </a>
      <div>
        <a
          className='text-[#0099FF] portrait:text-sm landscape:text-lg font-medium'
          href='https://www.facesign.ai/'
        >
          Learn more
        </a>
      </div>
    </div>
  )

  const title = (
    <div className='flex flex-row w-full justify-start py-[30px] px-4'>
      <span className='text-gray-800 font-bold text-[20px]'>
        Try FaceSign yourself
      </span>
    </div>
  )

  const description = (
    <div className='flex flex-col w-full justify-center px-[15px]'>
      <p className='text-blackAlpha-800 font-extralight portrait:text-md landscape:text-lg'>
        Here is a brief demo of FaceSign’s capabilities, in a simple multifactor authentication scenario.
      </p>
    </div>
  )

  const submitButton = (
    <div className='flex w-full pt-[15px] px-[15px]'>
      <button
        disabled={loading}
        className='flex w-full items-center justify-center rounded-[8px] h-12 bg-[#16C95D] text-white text-xl font-medium mt-4'
        onClick={submit}
      >
        Try our demo
      </button>
    </div>
  )

  const input = (
    <div className='flex flex-col w-full pt-8 px-4'>
      <input
        type='email'
        name='email'
        id='email'
        onFocus={() => setError(null)}
        value={value}
        disabled={loading}
        onChange={e => setValue(e.target.value)}
        className='block w-full rounded-[8px] border-0 h-[48px] pl-4 pr-4 text-gray-900 ring-1 ring-inset ring-[#E2E8F0] placeholder:font-medium placeholder:text-gray-500 focus:ring-gray-400 focus:ring-inset focus:ring-blackAlpha-800 sm:text-sm sm:leading-6 outline-none'
        placeholder='Enter your email'
        onKeyDownCapture={e => {
          if (e.code === 'Enter') {
            submit()
          }
        }}
      />
      <div className='pt-3'>
        {error && (
          <span className='text-xs text-red-500'>
            <i>{error}</i>
          </span>
        )}
      </div>
    </div>
  )

  const confirmation = (
    <ConfirmationCheckbox
      isChecked={agreed}
      onChange={(v: boolean) => {
        setAgreed(v)
      }}
      isError={false}
    />
  )

  return (
    <div className='h-full w-full'>
      <div className='flex w-full h-full flex-col flex-1 items-center'>
        {heading}
        <hr className='border-gray-100 w-full' />
        <div className='flex flex-col max-w-2xl landscape:mt-24'>
          {title}
          {description}
          <div className='flex flex-col landscape:max-w-lg self-center w-full'>
            {input}
          </div>
          <div className='flex flex-col landscape:max-w-sm self-center w-full'>
            <div className='landscape:max-w-xs self-center w-full'>
              {confirmation}
            </div>
            {submitButton}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EnterEmail
