import { useEffect, useState, useRef } from 'react'
import jsLogger from 'js-logger'
import * as Sentry from '@sentry/react'

import EnterEmail from 'pages/EnterEmail'
import EmailSent from 'pages/EmailSent'
import { conversationStart, setChunksCompleted } from 'controllers/main'
import Conversation from 'pages/Conversation'
import Recap from 'pages/RecapV2'
import { TwoFactorT } from 'types/twoFactor'
import { getBackClient, BackClient } from 'controllers/back'

const defaultAvatar = 'heygen'

const App = () => {
  const [initializing, setInitializing] = useState(true)
  const [verificationId, setVerificationId] = useState<string | null>(null)
  const [interactionId, setInteractionId] = useState<string>()
  const [emailSent, setEmailSent] = useState<string>()
  const [isComplete, setIsComplete] = useState(false)
  const [recordStopped, setRecordStopped] = useState(false)
  const chunksRef = useRef<{ user: Blob[]; avatar: Blob[] }>({
    user: [],
    avatar: []
  })
  const [initVersion, setInitVersion] = useState<0 | 1>(0)
  const [variant, setVariant] = useState<TwoFactorT.VariantT>('OpenAI')
  const [isTest, setIsTest] = useState(false)
  const [duration, setDuration] = useState<number>(0)
  const [model, setModel] = useState<string | null>(null)
  const [dgKey, setDgKey] = useState<string>()
  const [avatar, setAvatar] = useState<'azure' | 'heygen'>(defaultAvatar)
  const [azureKey, setAzureKey] = useState<{ token: string; region: string }>()
  const [heygenKey, setHeygenKey] = useState<string>()
  const [steps, setSteps] = useState<TwoFactorT.StepT[]>([])
  const [videoSavingDisabled, setVideoSavingDisabled] = useState(true)
  const backClientRef = useRef<BackClient>(getBackClient())
  const [recapData, setRecapData] = useState<TwoFactorT.RecapT | null>(null)

  useEffect(() => {
    const timeoutValue = 3000
    const check = () => {
      setTimeout(() => {
        if (recordStopped && interactionId) {
          setIsComplete(true)
          setChunksCompleted(interactionId, duration)
          jsLogger.debug('SHOW RECAP')
        } else {
          check()
        }
      }, timeoutValue)
    }
    if (recordStopped) {
      check()
    }
  }, [recordStopped, interactionId, duration])

  const getVariant = () => {
    const url = new URL(window.location.href)
    const v = url.searchParams.get('variant')
    switch (v) {
      case '2':
        return 'OpenAI'
      default:
        return 'LlmSystem'
    }
  }

  useEffect(() => {
    const url = new URL(window.location.href)
    const id = url.searchParams.get('id')
    setVerificationId(id)
    const initV = url.searchParams.get('init')
    const recap = url.searchParams.get('recap')
    const test = url.searchParams.get('test')
    const noVideo = true
    // const noVideo = url.searchParams.get('nv') !== null
    if (test) {
      setIsTest(true)
    }
    // if (noVideo) {
    setVideoSavingDisabled(true)
    // }
    const variant: TwoFactorT.VariantT = getVariant()
    setVariant(variant)
    const avatarParam = url.searchParams.get('a')
    let _avatar: 'azure' | 'heygen' = defaultAvatar
    if (avatarParam !== null) {
      _avatar = url.searchParams.get('a') === '1' ? 'azure' : 'heygen'
      setAvatar(_avatar)
    }

    const model: string | null = url.searchParams.get('model')
    setModel(model)

    jsLogger.debug('session start', { url, id, variant, _avatar })

    if (recap) {
      setIsComplete(true)
      setInteractionId(recap)
      setInitializing(false)
    } else {
      if (initV && initV === 'v1') {
        setInitVersion(1)
      }
      const run = async () => {
        if (id) {
          jsLogger.debug('conversationStart api call start')
          const apires = await conversationStart(id, variant, model, _avatar)
          jsLogger.debug('conversationStart api call end')
          if (apires) {
            if (apires.interactionId) {
              backClientRef.current.interactionStarted(
                apires.interactionId,
                noVideo
              )
              jsLogger.log('conversationStart interactionId', {
                interactionId: apires.interactionId
              })
              setInteractionId(apires.interactionId)
              Sentry.setContext('session', {
                verificationId: id,
                interactionId: apires.interactionId
              })
            }
            apires.steps && setSteps(apires.steps)
            apires.dgKey && apires.dgKey.key && setDgKey(apires.dgKey.key)
            apires.heygenKey && setHeygenKey(apires.heygenKey)
            apires.azureKey && setAzureKey(apires.azureKey)
          }
        }
        setInitializing(false)
      }
      run()
    }
  }, [])

  const onConversationFinished = () => {
    if (interactionId) {
      jsLogger.debug('conversation finished', { interactionId })
    }
    setRecordStopped(true)
  }

  const handleChunk = async (
    chunk: Blob,
    mimeType: string,
    role: 'user' | 'avatar'
  ) => {
    chunksRef.current[role].push(chunk)
    if (!videoSavingDisabled) {
      backClientRef.current.sendVideoChunk(chunk, mimeType, role)
    }
    // chunksRef.current[role].push(chunk)
    // if (interactionId) {
    //   const storagePath = interactionId
    //   try {
    //     const concatenatedBlob = new Blob(chunksRef.current[role], {
    //       type: mimeType
    //     })
    //     chunksRef.current[role] = []
    //     const id = generateId()
    //     setUploadQueue(v => {
    //       v.push(id)
    //       return v
    //     })
    //     await uploadBlobToStorage(
    //       concatenatedBlob,
    //       `${storagePath}/${role}_chunks/${id}`
    //     )
    //     setUploadQueue(v => {
    //       return without(v, id)
    //     })
    //   } catch (error) {
    //     jsLogger.error('Error uploading chunks:', error)
    //   }
    // }
  }

  const renderContent = () => {
    if (initializing) {
      return null
      // } else if (recordStopped && !isComplete) {
      //   return <Loading waitingForUpload />
    } else if (isComplete && interactionId) {
      return (
        <Recap
          interactionId={interactionId}
          chunks={chunksRef.current}
          recapData={recapData}
          isTest={isTest}
        />
      )
    } else if (interactionId && dgKey) {
      return (
        <Conversation
          verificationId={verificationId}
          interactionId={interactionId}
          onConversationFinished={onConversationFinished}
          handleChunk={handleChunk}
          initVersion={initVersion}
          isTest={isTest}
          setDuration={setDuration}
          dgKey={dgKey}
          azureKey={azureKey}
          heygenKey={heygenKey}
          steps={steps}
          setSteps={setSteps}
          setRecapData={setRecapData}
        />
      )
    } else if (emailSent) {
      return <EmailSent email={emailSent} />
    } else {
      return (
        <EnterEmail
          onComplete={(email: string) => setEmailSent(email)}
          variant={variant}
          model={model}
          isTest={isTest}
          avatar={avatar}
        />
      )
    }
  }

  return (
    <div className='w-full h-full relative flex justify-center items-center no-scrollbar'>
      {renderContent()}
    </div>
  )
}

export default App
