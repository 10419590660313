import { FC, useEffect, useRef, RefObject } from 'react'

type Props = {
  videoRef: RefObject<HTMLVideoElement | null>
}

const BackgroundRemover: FC<Props> = ({ videoRef }) => {
  const previousAnimationFrameTimestampRef = useRef(0)
  const tmpCanvasRef = useRef<HTMLCanvasElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const finishedRef = useRef<boolean>(false)

  useEffect(() => {
    window.requestAnimationFrame(makeBackgroundTransparent)
    return () => {
      finishedRef.current = true
    }
  }, [])

  const makeBackgroundTransparent = (timestamp: number) => {
    // console.log('makeBackgroundTransparent', timestamp)
    if (
      timestamp - previousAnimationFrameTimestampRef.current > 30 &&
      videoRef.current &&
      tmpCanvasRef.current
    ) {
      tmpCanvasRef.current.width = videoRef.current.videoWidth
      tmpCanvasRef.current.height = videoRef.current.videoHeight
      const tmpCanvasContext = tmpCanvasRef.current.getContext('2d', {
        willReadFrequently: true
      })
      if (tmpCanvasContext === null) {
        return
      }
      tmpCanvasContext.drawImage(
        videoRef.current,
        0,
        0,
        videoRef.current.videoWidth,
        videoRef.current.videoHeight
      )
      if (videoRef.current.videoWidth > 0) {
        const frame = tmpCanvasContext.getImageData(
          0,
          0,
          videoRef.current.videoWidth,
          videoRef.current.videoHeight
        )
        for (let i = 0; i < frame.data.length / 4; i++) {
          let r = frame.data[i * 4 + 0]
          let g = frame.data[i * 4 + 1]
          let b = frame.data[i * 4 + 2]

          if (g - 150 > r + b) {
            // Set alpha to 0 for pixels that are close to green
            frame.data[i * 4 + 3] = 0
          } else if (g + g > r + b) {
            // Reduce green part of the green pixels to avoid green edge issue
            const adjustment = (g - (r + b) / 2) / 3
            r += adjustment
            g -= adjustment * 2
            b += adjustment
            frame.data[i * 4 + 0] = r
            frame.data[i * 4 + 1] = g
            frame.data[i * 4 + 2] = b
            // Reduce alpha part for green pixels to make the edge smoother
            const a = Math.max(0, 255 - adjustment * 4)
            frame.data[i * 4 + 3] = a
          }
        }
        if (canvasRef.current) {
          canvasRef.current.width = videoRef.current.videoWidth
          canvasRef.current.height = videoRef.current.videoHeight
          const canvasContext = canvasRef.current.getContext('2d')
          canvasContext && canvasContext.putImageData(frame, 0, 0)
        }
      }

      previousAnimationFrameTimestampRef.current = timestamp
    }
    if (!finishedRef.current) {
      window.requestAnimationFrame(makeBackgroundTransparent)
    }
  }

  return (
    <>
      <canvas ref={tmpCanvasRef} width='720' height='1080' className='hidden' />
      <canvas
        width={720}
        height={1280}
        ref={canvasRef}
        id='canvas'
        className='bg-gray-700 absolute top-0 left-0 right-0 bottom-0 w-full h-full object-cover'
      ></canvas>
    </>
  )
}

export default BackgroundRemover
