import { FC, RefObject, useEffect, useRef } from 'react'
import jsLogger from 'js-logger'

type Props = {
  streamRef: RefObject<MediaStream | null>
  onVoiceDetected: () => void
  level?: number
}

const VoiceDetector: FC<Props> = ({
  streamRef,
  level = -50,
  onVoiceDetected
}) => {
  const analyzerRef = useRef<AnalyserNode | null>(null)
  const timer = useRef<number>(0)
  const domainDataRef = useRef<Uint8Array | null>(null)
  const fallbackTimeoutRef = useRef<number>(0)

  const detectSound = () => {
    if (analyzerRef.current && domainDataRef.current) {
      const bufferLength = analyzerRef.current.frequencyBinCount
      jsLogger.log('detectSound, bufferLength', bufferLength)
      analyzerRef.current.getByteFrequencyData(domainDataRef.current)
      let detected = false

      // jsLogger.log('detected', detected)

      for (let i = 0; i < bufferLength; i++) {
        if (domainDataRef.current[i] > 0) {
          jsLogger.log(
            'voice detected at freq',
            i,
            'with level',
            domainDataRef.current[i]
          )
          detected = true
          break
        }
      }

      if (detected) {
        onVoiceDetected()
      }
    }
  }

  useEffect(() => {
    // Voice Analizer
    if (streamRef.current) {
      fallbackTimeoutRef.current = window.setTimeout(() => {
        jsLogger.warn(
          'WARNING: VoiceDetector could not detect sound during 3 sec'
        )
        onVoiceDetected()
      }, 3000)
      jsLogger.log('start sound analysing')

      const audioContext = new AudioContext()
      jsLogger.log('audioContext created', audioContext)
      const audioStreamSource = audioContext.createMediaStreamSource(
        streamRef.current
      )
      jsLogger.log('audioStreamSource created', audioStreamSource)
      const analyser = audioContext.createAnalyser()

      analyser.fftSize = 256
      analyser.minDecibels = level
      analyser.maxDecibels = -10
      analyser.smoothingTimeConstant = 0.85
      audioStreamSource.connect(analyser)
      analyzerRef.current = analyser

      jsLogger.log('analyser created', audioStreamSource)

      const bufferLength = analyser.frequencyBinCount
      domainDataRef.current = new Uint8Array(bufferLength)

      timer.current = window.setInterval(detectSound, 500)
    } else {
      clearInterval(timer.current)
    }

    return () => {
      clearInterval(timer.current)
      clearTimeout(fallbackTimeoutRef.current)
    }
  }, [])

  return null
}

export default VoiceDetector
