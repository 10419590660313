import { FC, ReactNode } from 'react'

type Props = {
  children?: ReactNode
  version?: string
}

const PageContainer: FC<Props> = ({ children, version }) => {
  return (
    <div className='h-full w-full flex flex-col justify-between flex-1 max-w-xl relative'>
      {children}
      <div className='absolute right-1 bottom-0 text-gray-400 text-[8px] z-20 drop-shadow-md'>
        {version && <span>{version}</span>}
      </div>
    </div>
  )
}

export default PageContainer
