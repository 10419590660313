import React from 'react'

import { ReactComponent as IconChevronDown } from 'shared/assets/faChevronDown.svg'

const Accordion = ({
  children,
  show,
  setShow,
  showPlaceholder,
  showHeader
}: {
  children: React.ReactNode
  show: boolean
  setShow: (v: boolean) => void
  showPlaceholder?: boolean
  showHeader?: boolean
}) => {
  const toggle = () => setShow(!show)

  if (show) {
    return (
      <div className='bg-blackAlpha-700 flex-col items-start justify-start px-4 py-2.5'>
        {showHeader && (
          <div
            className='flex items-center justify-between py-[5px]  cursor-pointer w-full'
            onClick={toggle}
          >
            <p className='text-white text-sm font-semibold'>Test 1</p>
            <IconChevronDown className='text-white rotate-180' />
          </div>
        )}
        {children}
      </div>
    )
  } else if (showPlaceholder) {
    return (
      <div
        className='flex items-center justify-between py-[5px] px-2.5 cursor-pointer text-gray-600 rounded-t bg-blackAlpha-800'
        onClick={toggle}
      >
        <p className='text-white font-semibold text-sm'>Test 2</p>
        <IconChevronDown />
      </div>
    )
  } else {
    return null
  }
}

export default Accordion
