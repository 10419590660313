import {
  useRef,
  useEffect,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState
} from 'react'

import RVideo from 'shared/components/recorder/Rvideo'
import get from 'lodash/get'
import has from 'lodash/has'
import VoiceDetector from 'shared/components/VoiceDetector'
import { IHeyGenConnect } from 'shared/components/HeyGenConnectSdk'
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk'
import jsLogger from 'js-logger'

type Props = {
  onAvatarPlayingFinished: (latency: number) => void
  thereWasAnError?: string
  setThereWasAnError?: (v: string) => void
  onSessionStarted: () => void
  isRecording: boolean
  permissionsGranted: boolean
  handleChunk?: (videoBlob: Blob, mimeType: string, role: 'avatar') => void
  setDuration: (v: number) => void
  handleVideoClick: () => void
  azureKey: { token: string; region: string }
}

const AzureConnect: ForwardRefRenderFunction<IHeyGenConnect, Props> = (
  {
    onAvatarPlayingFinished,
    thereWasAnError,
    onSessionStarted,
    isRecording,
    permissionsGranted,
    handleChunk,
    setDuration,
    handleVideoClick,
    azureKey
  },
  ref
) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const audioRef = useRef<HTMLAudioElement>(null)
  const videoMutedRef = useRef<HTMLVideoElement>(null)
  const peerConnectionRef = useRef<RTCPeerConnection>()
  const videoRecorderRef = useRef<RVideo>()
  const streamRef = useRef<MediaStream | null>(null)
  const audioStreamRef = useRef<MediaStream | null>(null)
  const streamHasBeenSet = useRef<boolean>(false)
  const jobRef = useRef<() => void | null>(null)
  const jobStartTime = useRef<number>(0)
  const jobRunTime = useRef<number>(0)
  const [voiceDetectorEnabled, setVoiceDetectorEnabled] = useState(false)
  const messagePartsRef = useRef<string[]>([])
  const avatarSynthesizerRef = useRef<SpeechSDK.AvatarSynthesizer>()
  const permissionsGrantedRef = useRef<boolean>(false)
  const [idleVisible, setIdleVisible] = useState(true)

  useImperativeHandle(ref, () => ({
    say: (t: string) => {
      startTalk(t)
    },
    cancel: cancelTalking,
    stop: stopSession,
    play
  }))

  useEffect(() => {
    connect()
    return () => {
      stopSession()
      const pc = peerConnectionRef.current
      if (pc) {
        pc.close()
      }
    }
  }, [])

  const play = () => {
    if (!streamHasBeenSet.current) {
      playVideo()
    }
  }

  const cancelTalking = async () => {
    if (avatarSynthesizerRef.current) {
      jsLogger.log('cancelTalking')
      const stopSpeakingRes =
        await avatarSynthesizerRef.current.stopSpeakingAsync()

      jsLogger.log('stopSpeakingRes', stopSpeakingRes)
    }
    onAvatarPlayingFinished(0)
  }

  useEffect(() => {
    jsLogger.log('USE EFFECT permissionsGranted', permissionsGranted)
    if (permissionsGranted) {
      permissionsGrantedRef.current = true
      // setTimeout(playVideo, 500)
      playVideo()
    }
  }, [permissionsGranted])

  // const onVideoRecordingStopped = async () => {
  //   if (onRecordingComplete && videoRecorderRef.current) {
  //     jsLogger.log(
  //       'HEYGEN: onVideoRecordingStopped, blobs amount',
  //       videoRecorderRef.current.blobs.length
  //     )
  //     jsLogger.log('recorder vide type', videoRecorderRef.current.mimeType)

  //     const recordedBlobs = videoRecorderRef.current.blobs
  //     const videoBlob =
  //       recordedBlobs.length === 1
  //         ? recordedBlobs[0]
  //         : new Blob(recordedBlobs, {
  //           type: videoRecorderRef.current.mimeType
  //         })
  //     onRecordingComplete(
  //       videoBlob,
  //       videoRecorderRef.current.mimeType,
  //       videoRecorderRef.current.duration
  //     )
  //   }
  // }

  const htmlEncode = (text: string) => {
    const entityMap = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#39;',
      '/': '&#x2F;'
    }

    return String(text).replace(/[&<>"'/]/g, match => get(entityMap, match))
  }

  const avatarCompleted = () => {
    jsLogger.log('avatar finished talking')
    if (messagePartsRef.current.length > 0) {
      jsLogger.log('some parts of the phrase are left', {
        parts: messagePartsRef.current
      })
      startTalk(messagePartsRef.current[0])
    } else {
      onAvatarPlayingFinished(jobRunTime.current - jobStartTime.current)
    }
  }

  const startTalk = async (msg: string) => {
    jsLogger.debug('startTalk', { msg })
    const ar =
      msg.length > 10000
        ? msg.replace(/([.?!])\s*(?=[A-Z])/g, '$1|').split('|')
        : [msg]
    jsLogger.log('PHRASES ARRAY', ar)
    if (messagePartsRef.current.length > 0) {
      msg = messagePartsRef.current[0]
      messagePartsRef.current.shift()
    } else if (ar.length > 1) {
      jsLogger.log('the message is split on several phrases', { parts: ar })
      msg = ar[0]
      ar.shift()
      messagePartsRef.current = ar
    }
    jsLogger.log('Azure: call speak', { text: msg })
    const ttsVoice = 'en-US-AvaMultilingualNeural'
    const spokenSsml = `<speak version='1.0' xmlns='http://www.w3.org/2001/10/synthesis' xmlns:mstts='http://www.w3.org/2001/mstts' xml:lang='en-US'><voice name='${ttsVoice}'><mstts:ttsembedding speakerProfileId=''><mstts:leadingsilence-exact value='0'/>${htmlEncode(
      msg
    )}</mstts:ttsembedding></voice></speak>`

    try {
      if (avatarSynthesizerRef.current) {
        const sResult = await avatarSynthesizerRef.current.speakSsmlAsync(
          spokenSsml
        )
        jsLogger.log('sResult', sResult)
      } else {
        jsLogger.warn('avatarSynthesizerRef is not initialized')
      }
    } catch (e) {
      jsLogger.warn('speakSsmlAsync error', e)
    }
    avatarCompleted()
  }

  const playVideo = () => {
    const granted = permissionsGrantedRef.current
    jsLogger.log('%cplayVideo, permisssions granted', 'color: gold;', granted)
    const stream = streamRef.current
    // const v = permissionsGranted ? videoRef.current : videoMutedRef.current
    if (!granted) {
      jsLogger.log('playVideo: permissions are not granted, exit playVideo')
      return null
    }
    const v = videoRef.current
    // const v = videoRef.current
    if (stream && v) {
      if (!videoRecorderRef.current) {
        videoRecorderRef.current = new RVideo(
          stream,
          (v: number) => setDuration(v),
          onNewChunk
        )
      }
      jsLogger.log('pause current video')
      v.pause()

      v.srcObject = stream
      // v.muted = false
      streamHasBeenSet.current = true
      // v.muted = false
      const playPromise = v.play()
      if (v.paused && has(playPromise, 'then')) {
        v.play()
          .then(() => {
            jsLogger.log('--> Video Play success')
          })
          .catch(e => jsLogger.log('--> Video Play error', e.message))
      } else {
        jsLogger.log('Video is not paused')
      }
      playAudio()
      onSessionStarted()
      // startSession(sessionIdRef.current, sdpRef.current)
    } else {
      jsLogger.warn('playVideo stream or videoref is not ready')
    }
  }

  const playAudio = async () => {
    const granted = permissionsGrantedRef.current
    jsLogger.log('%cplayVideo, permisssions granted', { granted })
    const stream = audioStreamRef.current
    // const v = permissionsGranted ? videoRef.current : videoMutedRef.current
    if (!granted) {
      jsLogger.log('playAudio: permissions are not granted, exit playAudio')
      return null
    }
    const v = audioRef.current
    // const v = videoRef.current
    if (stream && v) {
      v.pause()
      v.srcObject = stream
      v.play()
    } else {
      jsLogger.warn('playAudio stream or audioRef is not ready')
    }
  }

  const setVideoElement = async (stream: MediaStream) => {
    jsLogger.log('setVideoElement')
    if (!stream) return
    streamRef.current = stream
    jsLogger.log(
      'setVideoElement: permissions granted',
      permissionsGrantedRef.current
    )
    playVideo()
    // if (permissionsGranted) {
    //   // playVideo()
    // }
  }

  const setAudioElement = async (stream: MediaStream) => {
    jsLogger.log('setAudioElement')
    if (!stream) return
    audioStreamRef.current = stream
    jsLogger.log('setAudioElement: permissions granted', {
      granted: permissionsGrantedRef.current
    })
    playAudio()
  }

  const newSession = async () => {
    jsLogger.log('AZURE: newSession')
    const speechSynthesisConfig = SpeechSDK.SpeechConfig.fromAuthorizationToken(
      azureKey.token,
      azureKey.region
    )

    speechSynthesisConfig.speechSynthesisLanguage = 'en-US'
    speechSynthesisConfig.speechSynthesisVoiceName =
      'en-US-AvaMultilingualNeural'
    jsLogger.log('speechSynthesisConfig', speechSynthesisConfig)

    const videoFormat = new SpeechSDK.AvatarVideoFormat()
    const videoCropTopLeftX = 0
    const videoCropBottomRightX = 1920
    videoFormat.setCropRange(
      new SpeechSDK.Coordinate(videoCropTopLeftX, 0),
      new SpeechSDK.Coordinate(videoCropBottomRightX, 1080)
    )
    const talkingAvatarCharacter = 'lisa'
    const talkingAvatarStyle = 'casual-sitting'
    const avatarConfig = new SpeechSDK.AvatarConfig(
      talkingAvatarCharacter,
      talkingAvatarStyle,
      videoFormat
    )
    avatarConfig.customized = false
    avatarConfig.backgroundColor = 'a73812'
    avatarSynthesizerRef.current = new SpeechSDK.AvatarSynthesizer(
      speechSynthesisConfig,
      avatarConfig
    )
    avatarSynthesizerRef.current.avatarEventReceived = (s, e) => {
      // jsLogger.log('avatarSynthesizerRef event', s, e)
      let offsetMessage =
        ', offset from session start: ' + e.offset / 10000 + 'ms.'
      if (e.offset === 0) {
        offsetMessage = ''
      }
      jsLogger.log(
        'Azure Event received: ' +
          {
            date: new Date().toISOString(),
            description: e.description,
            offsetMessage,
            e
          }
      )
      if (e.description === 'SwitchToSpeaking') {
        setTimeout(() => setIdleVisible(false), 500)
      }
    }

    jsLogger.debug('azure avatar relay start')

    const url = `https://${azureKey.region}.tts.speech.microsoft.com/cognitiveservices/avatar/relay/token/v1`
    const relayResp = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${azureKey.token}`,
        Accept: 'application/json'
      }
    })
    jsLogger.debug('relayResp status', {
      status: relayResp.status,
      statusText: relayResp.statusText
    })
    const responseData = await relayResp.json()
    jsLogger.log('responseData', { responseData })
    const data = {
      iceServerUrl: responseData.Urls[0],
      iceServerUsername: responseData.Username,
      iceServerCredential: responseData.Password
    }
    // jsLogger.log('return data', data)
    return data
  }

  const connect = async () => {
    // call the new interface to get the server's offer SDP and ICE server to create a new RTCPeerConnection
    const sessionInfo = await newSession()
    // Create a new RTCPeerConnection
    peerConnectionRef.current = new RTCPeerConnection({
      iceServers: [
        {
          urls: [sessionInfo.iceServerUrl],
          username: sessionInfo.iceServerUsername,
          credential: sessionInfo.iceServerCredential
        }
      ]
    })

    // When audio and video streams are received, display them in the video element
    peerConnectionRef.current.ontrack = event => {
      // logEvent('Received the track', { kind: event.track })
      if (event.track.kind === 'video') {
        // event.track.enabled = false
        if (!streamRef.current) {
          jsLogger.log('Azure: video track received', {
            trackKind: event.track.kind
          })
          setVideoElement(event.streams[0])
        }
      } else if (event.track.kind === 'audio') {
        // event.track.enabled = false
        if (!audioStreamRef.current) {
          jsLogger.log('Azure: audio track received', {
            trackKind: event.track.kind
          })
          setAudioElement(event.streams[0])
        }
      }
    }

    // When ICE connection state changes, display the new state
    peerConnectionRef.current.oniceconnectionstatechange = () => {
      if (peerConnectionRef.current) {
        jsLogger.log('AZURE: ICE connection state', {
          state: peerConnectionRef.current.iceConnectionState
        })
      }
    }

    peerConnectionRef.current.addTransceiver('video', { direction: 'sendrecv' })
    peerConnectionRef.current.addTransceiver('audio', { direction: 'sendrecv' })

    peerConnectionRef.current.onconnectionstatechange = (event: Event) => {
      jsLogger.log('onconnectionstatechange event', event)
    }

    jsLogger.debug('Azure session creation completed')

    if (!avatarSynthesizerRef.current) {
      jsLogger.error('avatarSynthesizerRef is empty')
      return
    }

    try {
      const r = await avatarSynthesizerRef.current.startAvatarAsync(
        peerConnectionRef.current
      )
      if (r.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {
        jsLogger.log('Azure startAvatarAsync', { r })
      } else {
        jsLogger.warn(
          '[' +
            new Date().toISOString() +
            '] Unable to start avatar. Result ID: ' +
            r.resultId
        )
        if (r.reason === SpeechSDK.ResultReason.Canceled) {
          const cancellationDetails = SpeechSDK.CancellationDetails.fromResult(
            r as SpeechSDK.SpeechSynthesisResult
          )
          if (
            cancellationDetails.reason === SpeechSDK.CancellationReason.Error
          ) {
            jsLogger.error('Azure avatar error: ', {
              errorDetails: cancellationDetails.errorDetails
            })
          }
          jsLogger.warn('Unable to start avatar')
        }
      }
    } catch (e) {
      jsLogger.error('start avatar error', e)
    }
  }

  // const _stopSession = async (sessionId: string) => {
  //   const response = await fetch(`${SERVER_URL}/v1/streaming.stop`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'X-Api-Key': apiKey
  //     },
  //     body: JSON.stringify({ session_id: sessionId })
  //   })
  //   if (response.status === 500) {
  //     jsLogger.error('Server error')
  //     // updateStatus(statusElement, "Server Error. Please ask the staff for help");
  //     throw new Error('Server error')
  //   } else {
  //     const data = await response.json()
  //     return data.data
  //   }
  // }

  const stopSession = async () => {
    if (avatarSynthesizerRef.current) {
      await avatarSynthesizerRef.current.stopAvatarAsync()
    }
    if (videoRecorderRef.current) {
      videoRecorderRef.current.stop()
    }
  }

  const onVoiceDetected = () => {
    const job = jobRef.current
    if (job) {
      jsLogger.log('onVoiceDetected: run existing job')
      jobRunTime.current = Date.now()
      job()
    }
    setVoiceDetectorEnabled(false)
  }

  const onNewChunk = (blob: Blob) => {
    if (videoRecorderRef.current) {
      const mimeType = videoRecorderRef.current.mimeType
      handleChunk && handleChunk(blob, mimeType || '', 'avatar')
    } else {
      jsLogger.warn('onNewChunk: videoRecorderRef is empty')
    }
  }

  return (
    <div
      className='h-full w-full flex flex-col justify-between flex-1 max-w-2xl relative overflow-hidden'
      onClick={handleVideoClick}
    >
      <audio ref={audioRef} autoPlay={false} />
      {permissionsGranted && (
        <video
          key={'heygen_video'}
          id='heygen_video'
          ref={videoRef}
          className='w-full h-[105%] object-cover absolute left-0 top-0 right-0 bottom-0 bg-blackAlpha-800'
          playsInline
          // muted={isRecording}
          controls={false}
          autoPlay={false}
          muted={false}
          // onPlay={() => jsLogger.log('----> video: on play <-----')}
          // onCanPlay={() => {
          //   jsLogger.log('onCanPlay')
          //   playVideo()
          // }}
          onAbort={() => jsLogger.log('Video: onAbort')}
          onError={e => jsLogger.log('Video: onError', get(e, 'message'))}
        />
      )}
      {idleVisible && (
        <video
          key={'muted_video'}
          id='muted_video'
          src='azure_lisa_idle.mp4'
          ref={videoMutedRef}
          className='w-full h-[105%] object-cover absolute left-0 top-0 right-0 bottom-0'
          playsInline
          // muted={isRecording}
          // controls
          autoPlay
          loop
          muted
          onPlay={() => jsLogger.log('---->IDLE  video: on play <-----')}
          onCanPlay={() => {
            jsLogger.log('IDLE onCanPlay')
            // playVideo()
          }}
          onAbort={() => jsLogger.log('IDLE Muted Video: onAbort')}
          onError={e =>
            jsLogger.log('IDLE Muted Video: onError ' + get(e, 'message'))
          }
        />
      )}
      {/* <BackgroundRemover key={'heygenVideo'} videoRef={videoRef} /> */}

      {/* {!permissionsGranted && (
        <div className='absolute top-0 left-0 bottom-0 right-0 bg-gray-700 w-full h-full' />
      )} */}
      {isRecording && (
        <div className='absolute top-0 left-0 bottom-0 right-0 bg-black/60 w-full h-full' />
      )}
      {thereWasAnError && (
        <div className='absolute bottom-0 left-0 bg-white'>
          <p className='text-red'>{thereWasAnError}</p>
        </div>
      )}
      {voiceDetectorEnabled && (
        <VoiceDetector
          streamRef={streamRef}
          onVoiceDetected={onVoiceDetected}
        />
      )}
    </div>
  )
}

export default forwardRef(AzureConnect)
