import {
  forwardRef,
  ForwardRefRenderFunction,
  Ref,
  useState,
  useEffect,
  useImperativeHandle,
  useRef
} from 'react'

import { ReactComponent as IconMicrophone } from 'shared/assets/faMicrophone.svg'
import { ReactComponent as IconCamera } from 'shared/assets/faCamera.svg'
import { ReactComponent as IconClosedCaptioning } from 'shared/assets/faClosedCaptioning.svg'
import { ReactComponent as IconXMark } from 'shared/assets/faXMark.svg'
import map from 'lodash/map'

import { ReactComponent as FFLogo } from 'shared/assets/facesign_logo_gray.svg'
import CircleButton from 'shared/components/CircleButton'
import Tooltip from 'shared/components/Tooltip'
import Accordion from 'shared/components/Accordion'

const LANGUAGES = [
  'English (United States)',
  'English (Great Britain)',
  'Spanish (Spain)',
  'Portuguese (Brazil)',
  'Portuguese (Portugal)',
  'French'
]

export interface IControlPanel {
  hide: () => void
  click: () => void
  setSpeech: (v: string) => void
}

export type Step = { id: string; text: string; isAvatar: boolean }

type Props = {
  permissionsError: string | null
  controls: { camera: boolean; mic: boolean }
  setControls: ({ camera, mic }: { camera: boolean; mic: boolean }) => void
  handleClose: () => void
  permissionsChecked: boolean
  steps: Step[]
  userName: string | null
  isRecording: boolean
  orientation: 'portrait' | 'landscape' | ''
  devices: MediaDeviceInfo[]
  setSelectedDeviceId: (v: string | null) => void
  selectedDeviceId: string | null
}

const ControlPanel: ForwardRefRenderFunction<IControlPanel, Props> = (
  {
    permissionsError,
    controls,
    setControls,
    handleClose,
    permissionsChecked,
    steps,
    userName,
    isRecording,
    orientation,
    devices,
    setSelectedDeviceId,
    selectedDeviceId
  },
  ref: Ref<unknown>
) => {
  const [subtitlesShow, setSubtitlesShow] = useState<boolean>(false)
  const [languagesShow, setLanguagesShow] = useState<boolean>(false)
  const [hidding, setHidding] = useState<boolean>(false)
  const [speech, setSpeech] = useState<string>('')
  const [cameraSelectShow, setCameraSelectShow] = useState<boolean>(false)
  const messagesEndRef = useRef<HTMLDivElement>(null)

  useImperativeHandle(ref, () => ({
    click: () => {
      setHidding(!hidding)
    },
    setSpeech: (v: string) => setSpeech(v)
  }))

  useEffect(() => {
    if (isRecording) {
      setSpeech('')
    }
  }, [isRecording])

  const handleMicOrCameraClick = (type: 'mic' | 'camera') => {
    setControls({ ...controls, [type]: !controls[type] })
  }

  useEffect(() => {
    if (permissionsError) {
      setControls({ camera: false, mic: false })
    }
  }, [permissionsError])

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [steps])

  useEffect(() => {
    if (orientation === 'portrait') {
      setTimeout(() => {
        setHidding(true)
      }, 2000)
    }
  }, [])

  const renderStep = (s: Step) => {
    const speaker = s.isAvatar ? 'Fay' : userName ?? 'Anonymous'
    return (
      <p key={s.id} className='text-gray-100 text-sm font-light italic'>
        {speaker}: {s.text}
      </p>
    )
  }

  return (
    <div
      className={`transition-opacity duration-200 landscape:w-min landscape:mx-auto portrait:w-full absolute bottom-0 ${
        hidding ? 'opacity-0' : 'opacity-100'
      } left-0 right-0`}
    >
      <div className={hidding ? 'hidden' : ''}>
        <div className='relative landscape:w-full landscape:rounded-t portrait:w-full flex flex-shrink-0 flex-row gap-[20px] justify-between py-4 bg-blackAlpha-800 px-6'>
          <div className='flex flex-shrink-0 flex-grow-1 flex-row gap-[20px] absolute landscape:w-[116px] portrait:w-[calc(20%+3rem)]'>
            {!permissionsChecked && !hidding && (
              <Tooltip
                value='Enable Camera + Microphone'
                classNames='w-[calc(100%+2rem)] -left-4 -top-14'
              />
            )}
          </div>
          <CircleButton
            colorScheme={!controls.mic ? 'defaultActive' : 'default'}
            onClick={() => handleMicOrCameraClick('mic')}
            disabled={!!permissionsError}
          >
            {
              // FIXME: icons size
            }
            <IconMicrophone color='white' fontSize='1rem' />
          </CircleButton>
          <CircleButton
            colorScheme={!controls.camera ? 'defaultActive' : 'default'}
            onClick={() => handleMicOrCameraClick('camera')}
            disabled={!!permissionsError}
          >
            <IconCamera color='white' fontSize='1rem' />
          </CircleButton>
          {/* <CircleButton
            colorScheme={cameraSelectShow ? 'defaultActive' : 'default'}
            onClick={() => setCameraSelectShow(!cameraSelectShow)}
          >
            <FontAwesomeIcon
              icon={faCameraWeb}
              color='white'
              fontSize='1rem'
            />
          </CircleButton> */}
          <CircleButton
            colorScheme={subtitlesShow ? 'defaultActive' : 'default'}
            onClick={() => setSubtitlesShow(!subtitlesShow)}
          >
            <IconClosedCaptioning color='white' fontSize='1rem' />
          </CircleButton>
          <CircleButton
            colorScheme={languagesShow ? 'defaultActive' : 'default'}
            onClick={() => setLanguagesShow(!languagesShow)}
          >
            <p className='text-white text-xs'>EN</p>
          </CircleButton>
          <CircleButton colorScheme='red' onClick={handleClose}>
            <IconXMark color='white' fontSize='1rem' />
          </CircleButton>
        </div>
        <Accordion show={subtitlesShow} setShow={setSubtitlesShow}>
          <div className='max-h-32 overflow-auto'>
            {map(steps, renderStep)}
            {/* {isRecording && ( */}
            <p
              key={'speech'}
              className='text-gray-100 text-sm font-light italic'
            >
              Anonymous: {speech}
            </p>
            {/* )} */}
            <div ref={messagesEndRef} />
          </div>
        </Accordion>
        <Accordion show={languagesShow} setShow={setLanguagesShow}>
          {map(LANGUAGES, (l, i) => (
            <div className='flex items-center mb-4' key={i}>
              <input
                id={`language_${i}`}
                disabled={i !== 0}
                defaultChecked={i === 0}
                type='radio'
                value={l}
                name={l}
                className='w-4 h-4 text-teal-400 focus:ring-0 accent-[#38B2AC]'
              />
              <label
                htmlFor={`language_${i}`}
                className='ms-2 text-sm font-medium text-white'
              >
                {l}
              </label>
            </div>
          ))}
        </Accordion>
        <Accordion show={cameraSelectShow} setShow={setCameraSelectShow}>
          {map(devices, d => (
            <div
              className='flex items-center mb-4'
              key={d.deviceId}
              onClick={() => setSelectedDeviceId(d.deviceId)}
            >
              <input
                id={`device_${d.deviceId}`}
                type='radio'
                value={d.deviceId}
                name={d.label}
                className='w-4 h-4 text-teal-400 focus:ring-0 accent-[#38B2AC]'
                checked={selectedDeviceId === d.deviceId}
                onChange={() => setSelectedDeviceId(d.deviceId)}
              />
              <label
                htmlFor={`language_${d.deviceId}`}
                className='ms-2 text-sm font-medium text-white'
              >
                {d.label}
              </label>
            </div>
          ))}
        </Accordion>
        <div className='w-full mx-auto bg-blackAlpha-800 flex items-center justify-end p-1 pr-2'>
          <span className='pr-1 text-[6px] text-whiteAlpha-600'>
            Powered by
          </span>
          <FFLogo height={10} width={40} />
        </div>
      </div>
    </div>
  )
}

export default forwardRef(ControlPanel)
