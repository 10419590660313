import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/pro-light-svg-icons'

const Loading = ({ waitingForUpload }: { waitingForUpload: boolean }) => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <FontAwesomeIcon icon={faSpinner} size="4x" spin className="text-teal-400 mb-4" />
      {waitingForUpload && (
        <p className="text-lg text-gray-700">
          Processing your request. Please do not close the window.
        </p>
      )}
    </div>
  )
}

export default Loading
