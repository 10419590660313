const Tooltip = ({
  value,
  classNames
}: {
  value: string
  classNames?: string
}) => {
  return (
    <div className={`absolute ${classNames}`}>
      <div className='bg-blue-500 text-white rounded py-1 px-4'>
        <p className='text-sm text-center'>{value}</p>
        <svg
          className='absolute text-blue-500 h-2 w-full left-0 top-full'
          x='0px'
          y='0px'
          viewBox='0 0 255 255'
          xmlSpace='preserve'
        >
          <polygon className='fill-current' points='0,0 127.5,127.5 255,0' />
        </svg>
      </div>
    </div>
  )
}

export default Tooltip
